import React, { useState } from 'react';
import { Block, Layout, Third, Title, Button, Notice } from '@dragonchain-dev/dragon-kit';
import Numeral from 'numeral';
import { Layout as PageLayout, Leaderboard, SEO } from '../../components/_index';
import { useEffectOnceAsync } from '../../utils/useEffectCustom';
import time from '../../utils/Time';

const { ACCOUNT_URI } = process.env;
Numeral.register('locale', 'dc', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: '+',
    million: 'Million+',
    billion: 'Billion+',
    trillion: 'Trillion+',
  },
});

const Time = () => {
  const lb = 1;
  const count = 20;
  const [leaderboard, setLeaderboard] = useState();
  const [stats, setStats] = useState({});
  const [ethereumAddress, setEthereumAddress] = useState('');
  const [message, setMessage] = useState();

  useEffectOnceAsync(async () => {
    Numeral.locale('dc');
    const data = await time.getLeaderboard(Math.max(lb - 6, 1), 13, count);
    setStats(await time.getGlobal());
    setLeaderboard(data);
  });

  const getScore = async () => {
    if (!ethereumAddress) return;
    if (/^(0x)?[0-9a-fA-F]{40}$/.test(ethereumAddress) === false) {
      setMessage('Invalid address format');
      return;
    }
    const score = await time.getScore(ethereumAddress);
    if (score.error) return;

    const data = await time.getLeaderboard(Math.max(score.lb - 6, 1), 13);
    setLeaderboard(data);
  };

  return (
    <PageLayout>
      <SEO
        title="Dragonchain Blockchain Community"
        description="Join the Dragonchain community to stay updated on all the latest news."
      />
      <Layout dark background="blue">
        <div className="block-header full">
          <Title color="white">TIME Leaderboard</Title>
          <p>TIME allows you to run nodes within Dragon Net and impacts your reward payouts.</p>
        </div>
        <Block type="thirds" style={{ marginBottom: 0 }}>
          <Third>
            <Title>{Numeral(stats.global).format('0 a')}</Title>
            <p>TIME</p>
          </Third>
          <Third middle>
            <Title>{Numeral(stats.average).format('0 a')}</Title>
            <p>Average TIME</p>
          </Third>
          <Third right>
            <Title>{Numeral(Math.round(stats.accounts / 1000) * 1000).format('0,0')}+</Title>
            <p>Accounts</p>
          </Third>
        </Block>
      </Layout>
      <Layout>
        <Block>
          <Title>Search your Ethereum address</Title>
          <div className="form">
            <div className="field">
              <input
                type="text"
                name="ethereumAddress"
                placeholder="Search by address..."
                value={ethereumAddress}
                onChange={e => setEthereumAddress(e.target.value)}
              />
            </div>
            <div className="actions">
              <Button type="button" onClick={() => getScore()}>
                Search
              </Button>
              <div className="message">{message}</div>
            </div>
          </div>
          {leaderboard && <Leaderboard data={leaderboard} ethereumAddress={ethereumAddress.toLowerCase()} />}
          <br />

        </Block>
      </Layout>
    </PageLayout>
  );
};

export default Time;
