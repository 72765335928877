class Time {
  constructor(basePath) {
    this.basePath = basePath;
    this.defaultOptions = {
      method: 'GET',
      mode: 'cors',
    };
  }

  makeRequest = async (path, options) => {
    const requestParams = { ...this.defaultOptions, ...options };

    try {
      const res = await fetch(`${this.basePath}${path}`, requestParams);
      const jsonResponse = await res.json();

      if (res.status === 404) return { error: 'Oops. Your address could not be found.' };
      if (res.status === 500) return { error: 'Oops. Come back a bit later.' };
      return jsonResponse;
    } catch (error) {
      return { error: error.message };
    }
  };

  // ---------------------------------------------------------------------
  // ACTIONS
  // ---------------------------------------------------------------------

  getScore = async address => {
    const response = await this.makeRequest(`/ss/${address}`);
    if (response.error) return response;
    if (response.ss === 0) return { error: 'Your wallet has no DRGN.' };
    return response;
  };

  getLeaderboard = async (from = 1, count = 20) => {
    const response = await this.makeRequest(`/lb/${from}/${count}`);
    if (response.error) return response;
    return response.leaderboard;
  };

  getGlobal = async () => {
    const response = await this.makeRequest('/stats');
    return response;
  };
}

const time = new Time(process.env.TIME_API);

export default time;
